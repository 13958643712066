<template>
    <!--not logged bar-->
    <div class = "menu bg-med-blue px-4 py-3 flex z-5" v-if="!global.firebaseToken"
    :class="{'hidden': !global.showMenu}">
    <!-- :class="{'hidden': !user.token}"> -->
        <!--account icon-->
        <router-link to = "/" class = "w-full text-center">
            <div class = "m-auto">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full" src = "@/assets/png/icons/account.png">
                </div>
                <div class = "text font-bold text-pal-1 text-sm">
                    Inicio
                </div>
            </div>
        </router-link>

        <!--info icon-->
        <router-link to = "/info" class = "w-full text-center">
            <div class = "m-auto">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full" src = "@/assets/png/icons/info.png">
                </div>
                <div class = "text font-bold text-pal-1 text-sm">
                    Información
                </div>
            </div>
        </router-link>
    </div>

    <!--logged in bar-->
    <div class = "menu bg-med-blue px-4 py-3 z-5 md:w-80 md:z-0 md:bg-pal-menu md:p-6" v-else-if="!global.updateMode">
      <!--brand-->
      <div class = "bg-white p-4 mb-3 -ml-6 rounded-r-md text-center flex hidden md:block">
        <img class = "w-2/6 m-auto" src = "@/assets/png/brand.png">
      </div>

      <div class = "w-full hidden md:block text-center mt-2 -mb-2">
        <img class = "w-2/5 rounded-full inline mb-4" src = "@/assets/jpg/profile.jpg">
      </div>

      <div class = "font-bold text-white text-center mb-3 hidden md:block">
        {{user.info.firstName}} {{user.info.firstLastName}}
      </div>

      <div class = "flex md:flex-col w-full text-pal-1 md:text-white md:space-y-3">
        <!--account icon-->
        <router-link to = "/account" class = "w-full text-center md:bg-white/40 rounded-md hover:opacity-70">
          <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
            <div class = "icon relatve w-7 inline-block">
              <img class = "inline w-full md:hidden" src = "@/assets/png/icons/account.png">
              <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/account-white.png">
            </div>
            <div class = "text font-bold text-sm my-auto">
                Inicio
            </div>
          </div>
        </router-link>

        <!--loans icon-->
        <router-link to = "/loans" class = "w-full text-center md:bg-white/40 rounded-md hover:opacity-70 hidden">
            <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
                <div class = "icon relatve w-7 inline-block">
                    <!-- <img class = "inline w-full" src = "@/assets/png/icons/loans.png"> -->
                    <img class = "inline w-full md:hidden" src = "@/assets/png/icons/loans.png">
                    <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/loans-white.png">
                </div>
                <div class = "text font-bold text-sm my-auto">
                    Préstamos
                </div>
            </div>
        </router-link>

        <!--info icon-->
        <router-link to = "/info" class = "w-full text-center md:bg-white/50 rounded-md hover:opacity-70">
            <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full md:hidden" src = "@/assets/png/icons/info.png">
                    <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/info-white.png">
                </div>
                <div class = "text font-bold text-sm my-auto">
                    Información
                </div>
            </div>
        </router-link>

        <router-link to = "/profile" class = "w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70">
            <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full md:hidden" src = "@/assets/png/icons/account.png">
                    <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/account-white.png">
                </div>
                <div class = "text font-bold text-sm my-auto">
                    Perfil
                </div>
            </div>
        </router-link>

        <router-link to = "/updateData" class = "w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70">
            <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full md:hidden" src = "@/assets/png/icons/briefcase.png">
                    <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/briefcase-white.png">
                </div>
                <div class = "text font-bold text-sm my-auto">
                    Actualizar Datos
                </div>
            </div>
        </router-link>

        <div class = "logout w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70 cursor-pointer" @click="logout">
            <div class = "m-auto md:flex md:w-full md:px-4 md:py-2">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full md:hidden" src = "@/assets/png/icons/account.png">
                    <img class = "inline w-full hidden md:inline w-5" src = "@/assets/png/icons/white/account-white.png">
                </div>
                <div class = "text font-bold text-sm my-auto">
                    Cerrar Sesión
                </div>
            </div>
        </div>

        <!--support icon-->
        <!--<router-link to = "/support" class = "w-full text-center">
            <div class = "m-auto">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full" src = "@/assets/png/icons/support.png">
                </div>
                <div class = "text font-bold text-pal-1 text-sm">
                    Soporte
                </div>
            </div>
        </router-link>-->
      </div>
    </div>

    <div class = "overlay fixed w-full h-full overflow-x-hidden z-100"
    :class = "{'pointer-events-none': !global.showMenu}"
    v-if="user.token != null">
        <div class = "overlay absolute w-full h-full left-0 top-0 cursor-pointer bg-black/40 transition-opacity duration-500 backdrop-blur-sm"
        @click="global.showMenu = false"
        :class = "{'opacity-0': !global.showMenu}">
        </div>
        <div class = "panel bg-lit-blue h-full w-4/5 absolute right-0 left-auto p-6 flex flex-col transition-right duration-300"
        :class = "{'-right-full': !global.showMenu}">

            <div class = "flex mb-4">
                <div class = "w-full">
                    <img class = "w-12" src = "@/assets/png/brand.png">
                </div>
                <div class = "w-full text-right">
                    <img class = "h-6 inline cursor-pointer" src = "@/assets/png/icons/close.png"  @click="global.showMenu = false">
                </div>
            </div>

            <div class = "h-full flex">
                <div class = "w-full my-auto text-center">
                    <img class = "w-1/2 rounded-full inline mb-4" src = "@/assets/jpg/profile.jpg">
                    <div class = "name font-bold mb-3 text-lg">
                        {{user.info.firstName}} {{user.info.firstLastName}}
                    </div>
                    <router-link to = "/profile" class = "bg-white font-bold rounded-xl p-2 drop-shadow mb-4 block opacity-100"
                    @click="global.showMenu = false">
                        Perfil
                    </router-link>
                    <router-link to = "/updateData" class = "bg-white font-bold rounded-xl p-2 drop-shadow mb-4 block opacity-100"
                    @click="global.showMenu = false">
                        Actualizar Datos
                    </router-link>
                    <div class = "bg-white font-bold rounded-xl p-2 drop-shadow mb-4 cursor-pointer" @click="logout">
                        Cerrar Sesión
                    </div>
                    <div class = "bg-black/10 p-4 rounded-xl text-center hidden">
                        <img class = "w-1/3 inline-block" src = "@/assets/svg/bell.svg"><br>
                        No tienes notificaciones nuevas
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useGlobalStore } from '@/store/GlobalStore';
    import { useUserStore } from '@/store/UserStore';

    export default {
        data() {
            return {
            }
        },

        setup() {
            const global = useGlobalStore();
            const user = useUserStore();
            return { global, user };
        },

        methods: {
            logout() {
              console.log('EXECUTED LOG OUT')
              this.user.signOut()
                .then(()=>{
                  this.$router.go();
                })
            }
        }

    }
</script>

<style scoped lang="scss">
  .menu {
    z-index: 5;
  }

  .overlay {
    z-index: 10;
  }

  .panel {
    z-index: 15;
    padding-top: calc(24px + env(safe-area-inset-top));
  }
    
  @media only screen and (max-width: 768px) {
    .menu {
        box-shadow: 0 -2px 8px rgba(black,0.075);
        padding-bottom: calc(.75rem + env(safe-area-inset-bottom));

        a:not(.router-link-active) {
        opacity: .5;
        } 
    }
  }

  @media only screen and (min-width: 768px) {
    .menu {
      a:not(.router-link-active) {
        background-color: rgba(255,255,255,.15) !important;
        //border: 1px white;
        }

      .logout {
        background-color: rgba(255,255,255,.15) !important;
      }
    }
  }

</style>