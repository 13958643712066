import { defineStore } from 'pinia';
import { httpsCallable } from 'firebase/functions';
import { useUserStore } from '@/store/UserStore';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';

export const useGlobalStore = defineStore('global', {
  state() {
    // console.log('---- INITIALIZE GLOBAL STORE ----')
    const user = useUserStore();

    return {
      user,

      firebaseToken: null,
      firebaseTokenLoading: true,
      rapToken: null,
      
      bufferedRoute: null,
      showMenu: false,

      firebase : "",
      firestore: "",
      functions: "",
      fireauth: null,
      
      error: {
        active: false,
        message: '',
        ignore: false,
      },
      fatalError:false,//
      
      loadState: {
        firebaseToken: false,
        rapToken: false,
      },

      fetchingInternetConnection: true,
      isConnectedToInternet: false,
    };
  },

  actions: {
    //---------------------------------------------------------------------------------------------
    // GET RAP TOKEN
    //---------------------------------------------------------------------------------------------
    getRAPToken() {
      this.rapToken = null;

      //-------------------------------------------------------------------------------------------
      // TOKEN ON MEMORY
      //-------------------------------------------------------------------------------------------
      if (this.rapToken) {
        const time  = new Date(localStorage.getItem('rti'));
        const diff = Math.abs(new Date() - time);
        const mins = (diff/1000)/60;
        if (mins <= 5) {
          // console.log("GET RAP TOKEN (ON MEMORY):",this.rapToken);
          return Promise.resolve(this.rapToken);
        }
      }

      //-------------------------------------------------------------------------------------------
      // TOKEN ON STORAGE
      //-------------------------------------------------------------------------------------------
      const token = localStorage.getItem('rto');
      const time  = new Date(localStorage.getItem('rti'));

      if (token && time) {
        const diff = Math.abs(new Date() - time);
        const mins = (diff/1000)/60;
        if (mins <= 5) {
          this.rapToken = token;
          // console.log("GET RAP TOKEN (ON STORAGE):",this.rapToken);
          return Promise.resolve(this.rapToken);  
        }
      }

      //-------------------------------------------------------------------------------------------
      // TOKEN SERVUCE
      //-------------------------------------------------------------------------------------------
      let httpTokenServicesGetToken = httpsCallable(this.functions,"httpTokenServicesGetToken");
      return Promise.resolve(
        httpTokenServicesGetToken()
          .then((result) => {
            // console.log('result',result)
            this.rapToken = result.data.result.TOKEN;
            localStorage.setItem('rto',this.rapToken);
            localStorage.setItem('rti',new Date());
            // console.log("GET RAP TOKEN (SERVICE):",this.rapToken);
            //console.log('STORAGE RTO:',localStorage.getItem('rto'));
          })
          .catch((err) => {
            // this.throwError(err,'global.getRAPToken()');
            this.fatalError=true
            this.throwError('Ha ocurrido un error al obtener tu información desde la fuente (Código de error: 306)');
            throw err;
          })
        );
      
    },

    //---------------------------------------------------------------------------------------------
    // GET FIREBASE USER
    //---------------------------------------------------------------------------------------------
    getFirebaseUser() {
      if (this.firebaseToken) {
        // console.log("FIREASE AUTH STATE:",this.firebaseToken);
        this.firebaseTokenLoading = false;
        return Promise.resolve(this.firebaseToken);
      } else {
        return new Promise((resolve, reject) => {
           const unsubscribe = this.fireauth.onAuthStateChanged(user => {
              unsubscribe();

              this.firebaseTokenLoading = false;
              this.firebaseToken = user;
              
              // console.log("FIREASE AUTH STATE:",this.user.uid,user);
              this.fireauth.onAuthStateChanged(this.firebaseAuthListener);

              if (Capacitor.isNativePlatform()) {
                if (localStorage.getItem('exc'))
                  localStorage.removeItem('exc');
                else
                  this.user.signOut();
                }
              
              resolve(user);
           }, reject);
        });
      }
    },

    //---------------------------------------------------------------------------------------------
    // FIREBASE LISTENER
    //---------------------------------------------------------------------------------------------
    firebaseAuthListener(user) {
      if (!user) {
        // console.log('AUTH STATE LISTENER: NO USER');
        this.user.signOut();
      }
    },
  
    //---------------------------------------------------------------------------------------------
    // ERROR MODAL
    //---------------------------------------------------------------------------------------------
    throwError(message,name) {
      Network.getStatus().then((result)=>{
        this.fetchingInternetConnection = false;
        this.isConnectedToInternet = result.connected;

        if (this.isConnectedToInternet) {
          this.error.active = true;
          this.error.name = name;
          this.error.message = message;
        }
      })      
    },

    //---------------------------------------------------------------------------------------------
    // GET GLOBAL CONFIG
    //---------------------------------------------------------------------------------------------
    getGlobalConfig() {
      return new Promise((resolve,reject) => {

        let data = {
          method: "getGlobal",
          };
        let httpScreenServices = httpsCallable(this.functions,"httpConfigServices")
          return Promise.resolve(httpScreenServices(data)
          .then((result) => {
            this.config = result.data.data
            // console.log("Config Time:",this.config.sessionTime)
            //console.log("Global Config result: ",result.data.data)
            resolve(result)
          })
          .catch((err) => {
            reject(err)
          })
        );

      })
    }
  }
});
