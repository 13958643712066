<script>
export default {
    props: {
    color: String,
    },
};
</script>

<template>
<div class="spinner">
    <div v-for="index in 12" :key="index" :style="'background: '+color"></div>
</div>
</template>

<style lang = "scss">
    $segment-width: 25%;
    $segment-height: 7%;
    $segment-transalte-x: 100%;
    .spinner {
        display: inline-block;
        position: relative;
        min-width: 20px;
        aspect-ratio: 1;

        div {
            transform-origin: 0% 50%;
            position: absolute;
            animation: spinner 1.2s linear infinite;
            left: 50%;
            top: 50%;
            width: $segment-width;
            height: $segment-height;
            background-color: black;
            border-radius: 10%;

            &:nth-child(1) {
                transform: rotate(0deg) translateX($segment-transalte-x);
                animation-delay: -1.1s;
                }

            &:nth-child(2) {
                transform: rotate(30deg) translateX($segment-transalte-x);
                animation-delay: -1s;
                }

            &:nth-child(3) {
                transform: rotate(60deg) translateX($segment-transalte-x);
                animation-delay: -0.9s;
                }

            &:nth-child(4) {
                transform: rotate(90deg) translateX($segment-transalte-x);
                animation-delay: -0.8s;
                }

            &:nth-child(5) {
                transform: rotate(120deg) translateX($segment-transalte-x);
                animation-delay: -0.7s;
                }

            &:nth-child(6) {
                transform: rotate(150deg) translateX($segment-transalte-x);
                animation-delay: -0.6s;
                }

            &:nth-child(7) {
                transform: rotate(180deg) translateX($segment-transalte-x);
                animation-delay: -0.5s;
                }

            &:nth-child(8) {
                transform: rotate(210deg) translateX($segment-transalte-x);
                animation-delay: -0.4s;
                }

            &:nth-child(9) {
                transform: rotate(240deg) translateX($segment-transalte-x);
                animation-delay: -0.3s;
                }

            &:nth-child(10) {
                transform: rotate(270deg) translateX($segment-transalte-x);
                animation-delay: -0.2s;
                }

            &:nth-child(11) {
                transform: rotate(300deg) translateX($segment-transalte-x);
                animation-delay: -0.1s;
                }

            &:nth-child(12) {
                transform: rotate(330deg) translateX($segment-transalte-x);
                animation-delay: 0s;
                }
            }
        }

    @keyframes spinner {
        0% {
            opacity: 1;
            }
        100% {
            opacity: 0;
            }
        }
</style>
