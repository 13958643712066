import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
// import {  URLOpenListenerEvent } from '@capacitor/app';
import './index.css'
import router from './router';
import moment from 'moment'
moment.locale('es-mx');  
const app = createApp(App)

const formatter = new Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'HNL',
    // maximumFractionDigits: 2,
    currency: 'USD',
  });

app.config.globalProperties.$filters = {
  currency(value,coin) {
    coin = coin ? coin : '';
    value = value ? value : 0;
    if (value >= 0)
      return coin+" "+formatter.format(value.toFixed(2)).replace(/HNL/g, "")
    else
      return '-'+coin+formatter.format(Math.abs(value.toFixed(2))).replace(/HNL/g, "")
    },
    capitalize(miString) {
      miString = miString ? miString : '';
      return miString.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    },
    dniHND(p) {
      // var dniregex = /^\d{2}-\d{3}-\d{4}-\d{3}$/
      return p = p.substr(0, 4)+ '-' + p.substr(4, 4)+ '-' + p.substr(8, 13);
    },
    formatDateEspLat(date) {
      date = date ? date : new Date('YYYYMMDD');
      return date = moment(date,'YYYYMMDD').format('L') || "Fecha no valida";
    },
    formatDateRAP(date) {
      return date = moment(date).format('L') ||  "Fecha no valida";
    },
    formatYearRAP(date) {
      return date = (moment(date,"YYYYMMDD").format('YYYY') || "Fecha no valida").toString().toUpperCase() ;
    },
    formatMonthRAP(date) {
      date = (moment(date,"YYYYMMDD").format('MMMM') || "Fecha no valida").toString();
      return date = date[0].toUpperCase() + date.slice(1);
    },
    formatDayRAP(date) {
      return date = (moment(date,"YYYYMMDD").format('DD') || "Dia no valido").toString().toUpperCase() ;
    },
}
// app.config.globalProperties.$filters = {
//   capitalize(miString) {
//     miString = miString ? miString : '';
//     return miString.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
//   }
// }




app.use(createPinia()).use(router).mount('#app')