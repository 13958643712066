import { createRouter, createWebHistory } from "vue-router";
import { useGlobalStore } from "../store/GlobalStore";
import { useUserStore } from "../store/UserStore";

import { App } from "@capacitor/app";

const routes = [
  // --------------------------------------------------------------------------------------------
  // LOGIN
  // --------------------------------------------------------------------------------------------
  {
    path: "/",
    name: "home",
    component: () => import("../views/Account/LoginView.vue"),
    beforeEnter: routerGuardHome,
  },
  {
    path: "/reset",
    name: "ResetPasswordMobile",
    component: () => import("../views/Account/ResetPasswordMobile.vue"),
    beforeEnter: routerGuardHome,
  },
  // {
  //   path: '/reset/:oobCode/:apiKey',
  //   name: 'ResetPasswordMobile',
  //   component: () => import('../views/Account/ResetPasswordMobile.vue'),
  //   beforeEnter : routerGuardHome,
  // },
  {
    path: "/terms",
    name: "Terms",
    beforeEnter: routerGuardHome,
    component: () => import("../components/TermsAndConditions.vue"),
  },
  {
    path: "/login",
    redirect: () => {
      return "/";
    },
  },
  // --------------------------------------------------------------------------------------------
  // RECOVERY
  // --------------------------------------------------------------------------------------------
  /*{
        path: '/recovery',
        name: 'recovery',
        beforeEnter : routerNoGuard,
        component: () => import('../views/Account/RecoveryView.vue'),
      },*/

  // --------------------------------------------------------------------------------------------
  // ACCOUNT
  // --------------------------------------------------------------------------------------------
  {
    path: "/account",
    name: "account",
    redirect: "/account/overview",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/AccountView.vue"),
    children: [
      {
        path: "overview",
        name: "account-overview",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/OverviewView.vue"),
      },

      {
        path: "overview/:account",
        name: "account-overview-account",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/OverviewView.vue"),
      },

      {
        path: "fund/:account/:name/:typecode/",
        name: "account-fund",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/FundView.vue"),
      },
      // {
      //   path: 'fund/:account/:name',
      //   name: 'account-fund',
      //   beforeEnter : routerGuardGeneral,
      //   component: () => import('../views/Account/FundView.vue'),
      // },

      {
        path: "history",
        name: "account-history",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/HistoryView.vue"),
      },

      {
        path: "history/:company/:local",
        name: "account-history-single",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/CompanyView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // INFORMATION
  // --------------------------------------------------------------------------------------------
  {
    path: "/info",
    name: "info",
    redirect: "/info/general",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/InformationView.vue"),
    children: [
      {
        path: "general",
        name: "info-general",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/GeneralView.vue"),
      },

      {
        path: "agency/:id",
        name: "info-agency",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/AgencyView.vue"),
      },

      {
        path: "work-with-us",
        name: "info-work",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/WorkWithUsView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // LOANS
  // --------------------------------------------------------------------------------------------
  {
    path: "/loans",
    name: "loans",
    redirect: "/loans/overview",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/LoansView.vue"),
    children: [
      {
        path: "overview",
        name: "loans-overview",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/OverviewView.vue"),
      },

      {
        path: ":id",
        name: "loans-single",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/SingleView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // SUPPORT
  // --------------------------------------------------------------------------------------------
  {
    path: "/support",
    name: "support",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/AccountView.vue"),
  },

  // --------------------------------------------------------------------------------------------
  // PROFILE
  // --------------------------------------------------------------------------------------------
  {
    path: "/profile",
    name: "profile",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/User/ProfileView.vue"),
  },

  // --------------------------------------------------------------------------------------------
  // UPDATE DATA
  // --------------------------------------------------------------------------------------------
  {
    path: "/updateData",
    name: "user-data",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/User/UpdateDataView.vue"),
  },
];

// ------------------------------------------------------------------------------------------------
// GENERAL GUARD
// ------------------------------------------------------------------------------------------------
function routerGuardGeneral(to, from, next) {
  // console.log("ROUTER GUARD GENERAL");
  const global = useGlobalStore();

  global.getGlobalConfig().then(() => {
    const promises = [];
    // console.log("REQUESTING RAP TOKEN");
    promises.push(global.getRAPToken());
    // console.log("REQUESTING FIREBASE TOKEN");
    //promises.push(global.getGlobalConfig());
    promises.push(global.getFirebaseUser());

    /*promises.push(global.getGlobalConfig(() =>{
      global.getFirebaseUser()
    }));*/

    Promise.all(promises).then(() => {
      // console.log("RAP TOKEN AND FIREBASE REQUEST COMPLETED, MOVING TO:", to);

      if (global.firebaseToken && global.rapToken) {
        next();
        useUserStore().handleSessionTime(true);
      } else next("/");
    });
  });
}

// ------------------------------------------------------------------------------------------------
// HOME GUARD
// ------------------------------------------------------------------------------------------------
function routerGuardHome(to, from, next) {
  // console.log("ROUTER GUARD HOME");
  const global = useGlobalStore();

  global.getGlobalConfig().then(() => {
    const promises = [];
    // console.log("REQUESTING RAP TOKEN");
    promises.push(global.getRAPToken());
    // console.log("REQUESTING FIREBASE TOKEN");
    //promises.push(global.getGlobalConfig());
    promises.push(global.getFirebaseUser());

    Promise.all(promises).then(() => {
      // console.log("RAP TOKEN AND FIREBASE REQUEST COMPLETED, MOVING TO:", to);

      if (global.firebaseToken && global.rapToken) next("/account/overview");
      else {
        next();
        useUserStore().handleSessionTime(true);
      }
    });
  });
}

// ------------------------------------------------------------------------------------------------
// NO GUARD
// ------------------------------------------------------------------------------------------------
/*function routerNoGuard() {
  // console.log("ROUTER GUARD NO GUARD")
  const global = useGlobalStore();

  const promises = [];
  // console.log("REQUESTING RAP TOKEN");
  promises.push(global.getRAPToken());
  // console.log("REQUESTING FIREBASE TOKEN");
  promises.push(global.getFirebaseUser());
  useUserStore().handleSessionTime(true);
  }*/

// ------------------------------------------------------------------------------------------------
// ROUTER

// ------------------------------------------------------------------------------------------------
const router = createRouter({
  history: createWebHistory(import.meta.BASE_URL),
  routes,
});

try {
  App.addListener("appUrlOpen",  (event) => {
    // console.log("============== ANDROID DEEPLINKS =============");
    // console.log("============== ###################### =============");
    // console.log('event.url', event.url)
    const url = "https://piloto.rap.hn/"
    // console.log('url',url)

    let slug = (event.url).split(url).pop();
    // console.log('slug',slug)
    // console.log('"/reset/"+slug',"/reset/"+slug)
    
    if (slug == url) { 
      // console.log('"/reset/"+slug')
      router.router(`/${slug ? "/reset/"+slug : "/terms"}`);
    }else{
      // console.log('===== URL NO IGUALES =====')
    }
    // console.log("event", event);
    // console.log("event.url", event.url);

    // https://piloto.rap.hn/?mode=resetPassword&oobCode=MTc1WOcjPWOSSQc4CU7SOa45OlINuqUrseajHRJbnygAAAGMBHLoag&apiKey=AIzaSyApo6WPTZz3lP-cCUaRM4LH0nJpmyFzH1g&lang=en
    // var slug = event.url.split('rap.hn/').pop();
    // var slug = event.url.split(".rap.hn/").pop();
    //     slug=slug.toString()
    // console.log("SLUG", slug);

    // // const regex ='/https://piloto.rap.hn//i';
    // var slug2 = slug.replace("https://piloto.rap.hn/", "/reset");
    // console.log("SLUG.replace ", slug2);

    // if (slug.includes("piloto.rap.hn")) {
    //   router.push({
    //     name: "ResetPasswordMobile",
    //     path: "/reset?mode=resetPassword&oobCode=MTc1WOcjPWOSSQc4CU7SOa45OlINuqUrseajHRJbnygAAAGMBHLoag&apiKey=AIzaSyApo6WPTZz3lP-cCUaRM4LH0nJpmyFzH1g&lang=en",
    //   });
    // }

    //===
    // var url = event.url.split("rap.hn/");
    // console.log("url", url);
    // url = "/reset" + url;

    // console.log("SLUG URL=>>>>>", url);
    // if (url) {
    //   router.push(url);
    // } else {
    //   console.log("URL not found");
    //   router.push("/terms");
    // }
    // if (slug) {
    //   router.push({
    //     path: "/reset?mode=resetPassword&oobCode=MTc1WOcjPWOSSQc4CU7SOa45OlINuqUrseajHRJbnygAAAGMBHLoag&apiKey=AIzaSyApo6WPTZz3lP-cCUaRM4LH0nJpmyFzH1g&lang=en",
    //   });
    // }
  });
} catch (error) {
  // console.log("TRY ERROR=>", error);
}
export default router;
