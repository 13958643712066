import { defineStore } from "pinia";
import { useGlobalStore } from "@/store/GlobalStore";
import { httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Router from "../router";
import { ClientJS } from "clientjs";

export const useUserStore = defineStore("user", {
  state() {
    const global = useGlobalStore();
    return {
      token: null,
      jwt: null,
      sid: null,

      global,
      info: {
        code: "",
        id: "",
        idType: "",

        firstName: "",
        secondName: "",
        firstLastName: "",
        secondLastName: "",
        dob: "",

        picture: null,
      },

      accounts: [],
      account: null,

      jobs: [],
      loans: [],
      loanDetail: [],

      formLink: null,

      loadState: {
        validate: false,
        info: false,
        accounts: false,
        funds: false,
        summary: false,
        jobs: false,
        loans: false,
        loanDetail: false,
        formLink: false,
      },
      fatalErrorUser: false,
    };
  },

  actions: {
    //---------------------------------------------------------------------------------------------
    // GET USER TOKEN
    //---------------------------------------------------------------------------------------------
    getUserToken() {
      if (this.loadState.validate) {
        return Promise.resolve(this.info);
      } else {
        try {
          let data = {
            method: "getUserToken",
            token: this.global.rapToken,
          };

          let httpUserServices = httpsCallable(
            this.global.functions,
            "httpUserServices"
          );
          return Promise.resolve(
            httpUserServices(data)
              .then((result) => {
                if (result.data.status == 99) {
                  this.signOut();
                  return false;
                }

                if (result.data.status !== 2) {
                  this.token = result.data.data.TOKEN;
                  // this.token = "abcd1234!"
                  this.jwt = result.data.data.jwt;
                  this.sid = result.data.data.sid;

                  // console.log("GET USER TOKEN:", this.token);
                  // console.log("ONTAINED JWT:", this.jwt);
                  // console.log("BREAKPOINT 1");
                  // console.log("SID:", this.sid);

                  // console.log("SET LISTENER:", this.global.firestore, this.uid);
                  onSnapshot(
                    doc(
                      this.global.firestore,
                      "user",
                      this.global.firebaseToken.uid
                    ),
                    (doc) => {
                      if (doc.data().sid != this.sid) this.signOut();
                      // console.log("CURRENT DATA: ", doc.data());

                      //TODO este cpdigo es para ver si agreo funcionalida de cierre se sision pro passwor iseguro
                      // if(doc.data().changePassword==false){
                      //     this.fatalErrorUser=true
                      //     this.global.throwError('Hemos detectado que no cuentas con un contraseña robusta,por favor cambia tu contraseña');
                      // }
                    }
                  );

                  // console.log("BREAKPOINT 2");
                  this.info.code = result.data.data.COD_CLIENTE;
                  this.info.id = result.data.data.NUMERO_ID.replaceAll("-", "");
                  this.info.idType = result.data.data.TIPO_ID;

                  this.accounts = [];
                  // console.log(
                  //   "ITERATOR:",
                  //   result.data.data.CUENTAS.OT_SYSDE_INF_CUENTA
                  // );
                  result.data.data.CUENTAS.OT_SYSDE_INF_CUENTA.forEach(
                    (account) => {
                      this.accounts.push({
                        code: account.COD_CUENTA,
                        info: account,
                        coin: account.DES_MONEDA,
                        funds: {},
                        value: 0,
                        loaded: false,
                      });
                    }
                  );
                  this.account = this.accounts[0];

                  this.loadState.validate = true;
                  this.loadState.accounts = true;
                  this.loadState.funds = false;
                  this.sessionTimeTimer();

                  return this.token;
                } else {
                  // this.global.throwError(result,'user.getUserToken().results');
                  this.fatalErrorUser = true;
                  this.global.throwError(
                    "Ha ocurrido un error al obtener tu información"
                  );
                  throw result;
                }
              })
              .catch((err) => {
                // this.global.throwError(err,'user.getUserToken().results');
                this.fatalErrorUser = true;

                this.loadState.validate=false
                this.global.throwError(
                  "Ha ocurrido un error al obtener tu información. 6667"
                );
                throw err;
              })
          );
        } catch (err) {
          this.global.throwError(err, "user.getUserToken()");
          throw err;
        }
      }
    },

    //---------------------------------------------------------------------------------------------
    // GET ACCOUNT INFO
    //---------------------------------------------------------------------------------------------
    getAccountInfo() {
      let data = {
        token: this.global.rapToken,
        method: "getUserInfo",
      };

      let httpUserServices = httpsCallable(
        this.global.functions,
        "httpUserServices"
      );
      return Promise.resolve(
        httpUserServices(data)
          .then(() => {
            // console.log("CACHED USER INFO:", result);
            return true;
          })
          .catch((err) => {
            // this.global.throwError(err,'user.getAccountInfo');
            this.global.throwError("No se obtuvo información de tu usuario");
            throw err;
          })
      );
    },

    //---------------------------------------------------------------------------------------------
    // GET USER INFO
    //---------------------------------------------------------------------------------------------
    getUserInfo() {
      if (this.loadState.info) {
        return Promise.resolve(this.info);
      } else {
        try {
          let data = {
            token: this.global.rapToken,
            jwt: this.jwt,
            documentNumber: this.info.id,
            documentType: this.info.idType,
            method: "getGeneralInfo",
          };

          // console.log("data httpAffiliateServices", data);
          // console.log("this.global.functions", this.global.functions);

          let httpAffiliateServices = httpsCallable(
            this.global.functions,
            "httpAffiliateServices"
          );
          return Promise.resolve(
            httpAffiliateServices(data)
              .then((result) => {
                // console.log("RESULT OF GET USER INFO:", result);

                if (result.data.status == 99) {
                  this.signOut();
                  return false;
                }

                try {
                  const general = result.data.info["enc-Dtos-grales"];
                  this.info.data = general;

                  // console.log(this.info);

                  if (general["prim-nom"]) {
                    this.info.firstName = general["prim-nom"].toLowerCase();
                    this.info.firstName =
                      this.info.firstName.charAt(0).toUpperCase() +
                      this.info.firstName.slice(1);
                  }

                  if (general["seg-nom"]) {
                    this.info.secondName = general["seg-nom"].toLowerCase();
                    this.info.secondName =
                      this.info.secondName.charAt(0).toUpperCase() +
                      this.info.secondName.slice(1);
                  }

                  if (general["prim-ape"]) {
                    this.info.firstLastName = general["prim-ape"].toLowerCase();
                    this.info.firstLastName =
                      this.info.firstLastName.charAt(0).toUpperCase() +
                      this.info.firstLastName.slice(1);
                  }

                  if (general["seg-ape"]) {
                    this.info.secondLastName = general["seg-ape"].toLowerCase();
                    this.info.secondLastName =
                      this.info.secondLastName.charAt(0).toUpperCase() +
                      this.info.secondLastName.slice(1);
                  }

                  this.loadState.info = true;
                  return this.info;
                } catch (err) {
                  this.fatalErrorUser = true;
                  // this.global.throwError(err,'user.getUserInfo().result');
                  this.global.throwError(
                    "Ha ocurrido un error al obtener información del usuario (CODE ERROR:900)"
                  );
                  throw err;
                }
              })
              .catch((err) => {
                // console.log("err =>", err);
                
                

                this.fatalErrorUser = true;
                // this.global.throwError(err,'user.getUserInfo()');
                this.global.throwError(
                  "Ha ocurrido un error al obtener información del usuario (CODE ERROR:901)"
                );
                throw err;
              })
          );
        } catch (err) {
          this.fatalErrorUser = true;
          this.loadState.info = false;
          // this.global.throwError(err,'user.getUserInfo()');
          this.global.throwError(
            "Ha ocurrido un error al obtener información del usuario (CODE ERROR:902)"
          );
          throw err;
        }
      }
    },

    //---------------------------------------------------------------------------------------------
    // GET FUNDS
    //---------------------------------------------------------------------------------------------
    getFundsInfo: function (account) {
      if (account.loaded) {
        return Promise.resolve(account.funds);
      } else {
        try {
          let data = {
            token: this.global.rapToken,
            jwt: this.jwt,
            accountNumber: account.code,
            method: "getFundInfo",
          };

          let httpAffiliateServices = httpsCallable(
            this.global.functions,
            "httpAffiliateServices"
          );
          return Promise.resolve(
            httpAffiliateServices(data)
              .then((result) => {
                // console.log("GET FUNDS INFO:", result.data.status);

                if (result.data.status == 99) {
                  this.signOut();
                  return false;
                }

                const home = {
                  name: "Fondo de Vivienda",
                  value: 0,
                  percent: 0,
                  palette: 1,
                  symbol: "L",
                  typecode: 101,

                  component: {
                    individualVoluntary: {
                      name: "Individual voluntario",
                      value: 0,
                      percent: 0,
                      palette: 1,
                      symbol: "L",
                    },
                    individualMandatory: {
                      name: "Individual obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 2,
                      symbol: "L",
                    },
                    companyVoluntary: {
                      name: "Empresarial voluntario",
                      value: 0,
                      percent: 0,
                      palette: 3,
                      symbol: "L",
                    },
                    companyMandatory: {
                      name: "Empresarial obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 4,
                      symbol: "L",
                    },
                    historic: {
                      name: "Fondo histórico",
                      value: 0,
                      percent: 0,
                      palette: 5,
                      symbol: "L",
                    },
                    surplus: {
                      name: "Excedentes/Rendimientos",
                      value: 0,
                      percent: 0,
                      palette: 6,
                      symbol: "L",
                    },
                    /*incomeByDebt: {
                      name: 'Ingresos Por Mora',
                      value: 0, percent: 0, palette: 7, symbol: 'L',
                    },
                    personalLoans: {
                      name: 'Cuotas Présamos Personales',
                      value: 0, percent: 0, palette: 8, symbol: 'L',
                    }*/
                  },
                };

                const retirement = {
                  name: "Fondo de Pensión",
                  value: 0,
                  percent: 0,
                  palette: 2,
                  symbol: "L",
                  typecode: 102,
                  component: {
                    individualVoluntary: {
                      name: "Individual voluntario",
                      value: 0,
                      percent: 0,
                      palette: 1,
                      symbol: "L",
                    },
                    individualMandatory: {
                      name: "Individual obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 2,
                      symbol: "L",
                    },
                    companyVoluntary: {
                      name: "Empresarial voluntario",
                      value: 0,
                      percent: 0,
                      palette: 3,
                      symbol: "L",
                    },
                    companyMandatory: {
                      name: "Empresarial obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 4,
                      symbol: "L",
                    },
                  },
                };

                const unemployment = {
                  name: "Fondo de Cesantía",
                  value: 0,
                  percent: 0,
                  palette: 3,
                  symbol: "L",
                  typecode: 105,
                  component: {
                    companyVoluntary: {
                      name: "Empresarial voluntario",
                      value: 0,
                      percent: 0,
                      palette: 1,
                      symbol: "L",
                    },
                    companyMandatory: {
                      name: "Empresarial obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 2,
                      symbol: "L",
                    },
                  },
                };

                const retirementusd = {
                  name: "Fondo de Pensión USD",
                  value: 0,
                  percent: 0,
                  palette: 1,
                  symbol: "$",
                  typecode: 111,
                  component: {
                    individualVoluntary: {
                      name: "Individual voluntario",
                      value: 0,
                      percent: 0,
                      palette: 1,
                      symbol: "$",
                    },
                    individualMandatory: {
                      name: "Individual obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 2,
                      symbol: "$",
                    },
                    companyVoluntary: {
                      name: "Empresarial voluntario",
                      value: 0,
                      percent: 0,
                      palette: 3,
                      symbol: "$",
                    },
                    companyMandatory: {
                      name: "Empresarial obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 4,
                      symbol: "$",
                    },
                  },
                };

                const unemploymentusd = {
                  name: "Fondo de Cesantía USD",
                  value: 0,
                  percent: 0,
                  palette: 2,
                  symbol: "$",
                  typecode: 110,
                  component: {
                    companyVoluntary: {
                      name: "Empresarial voluntario",
                      value: 0,
                      percent: 0,
                      palette: 1,
                      symbol: "$",
                    },
                    companyMandatory: {
                      name: "Empresarial obligatorio",
                      value: 0,
                      percent: 0,
                      palette: 2,
                      symbol: "$",
                    },
                  },
                };

                const fundsArray = result.data.funds.sdo;
                fundsArray.forEach((value) => {
                  switch (value["cod-tipsaldo"]) {
                    case 13:
                      unemployment.component.companyMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 43:
                      unemployment.component.companyVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 10:
                      retirement.component.individualMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 11:
                      retirement.component.individualVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 12:
                      retirement.component.companyMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 44:
                      retirement.component.companyVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 17:
                      home.component.historic.value = value["mto-sdo"];
                      break;

                    case 18:
                      home.component.surplus.value = value["mto-sdo"];
                      break;

                    case 19:
                      home.component.individualVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 20:
                      home.component.companyVoluntary.value = value["mto-sdo"];
                      break;

                    /*case 25:
                      home.component.incomeByDebt.value = value['mto-sdo'];
                      break;*/

                    /*case 26:
                      home.component.personalLoans.value = value['mto-sdo'];
                      break;*/

                    case 27:
                      home.component.individualMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 28:
                      home.component.companyMandatory.value = value["mto-sdo"];
                      break;

                    case 37:
                      unemploymentusd.component.companyMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 38:
                      unemploymentusd.component.companyVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 31:
                      retirementusd.component.individualMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 30:
                      retirementusd.component.individualVoluntary.value =
                        value["mto-sdo"];
                      break;

                    case 35:
                      retirementusd.component.companyMandatory.value =
                        value["mto-sdo"];
                      break;

                    case 36:
                      retirementusd.component.companyVoluntary.value =
                        value["mto-sdo"];
                      break;
                  }
                });

                switch (account.coin) {
                  case "DOLARES ESTADOUNIDENSES":
                    account.funds = {
                      retirementusd,
                      unemploymentusd,
                    };
                    account.symbol = "$";
                    break;

                  case "LEMPIRAS":
                  default:
                    account.funds = {
                      home,
                      retirement,
                      unemployment,
                    };
                    account.symbol = "L";
                    break;
                }
                account.value = 0;

                // console.log("FUND HOME:", home);
                // console.log("FUND RETIREMENT:", retirement);
                // console.log("FUND UNEMPLOYMENT:", unemployment);

                for (let key in account.funds) {
                  let fund = account.funds[key];

                  fund.value = 0;

                  for (let key in fund.component)
                    fund.value += fund.component[key].value;

                  if (fund.value > 0)
                    //fund.component[key].percent = fund.component[key].value/fund.value*100
                    for (let key in fund.component)
                      fund.component[key].percent = Math.round(
                        (fund.component[key].value / fund.value) * 100
                      );

                  account.value += fund.value;
                }

                for (let key in account.funds) {
                  let fund = account.funds[key];
                  //fund.percent = fund.value/account.value*100);
                  fund.percent = Math.round((fund.value / account.value) * 100);
                }

                account.loaded = true;
                return account.funds;
              })
              .catch((err) => {
                this.global.throwError(err, "user.getFundsInfo");
                throw err;
              })
          );
        } catch (err) {
          this.global.throwError(err, "user.getFundsInfo()");
          throw err;
        }
      }
    },

    //---------------------------------------------------------------------------------------------
    // GET ACCOUNT SUMMARY
    //---------------------------------------------------------------------------------------------
    getAccountStatementSummary: function (account, initialdate, finaldate) {
      let data = {
        token: this.token,
        jwt: this.jwt,
        documentNumber: account.code,
        initialDate: initialdate,
        finalDate: finaldate,
        historical: true,
        method: "getAccountStatementSummary",
      };

      let httpAffiliateServices = httpsCallable(
        this.global.functions,
        "httpAffiliateServices"
      );
      return Promise.resolve(
        httpAffiliateServices(data)
          .then(() => {
            return this.account;
          })
          .catch((err) => {
            // this.global.throwError(err,'user.getAccountStatementSummary()');
            this.global.throwError(
              "Ha ocurrido un error al obtener el resumen (Código de error: 000)"
            );
            throw err;
          })
      );
    },

    //---------------------------------------------------------------------------------------------
    // GET ACCOUNT DETAIL
    //---------------------------------------------------------------------------------------------
    getAccountStatementDetail: function (
      account,
      initialdate,
      finaldate,
      typecode
    ) {
      // console.log("getAccountStatementDetail typecode", typecode);
      let data = {
        token: this.token,
        jwt: this.jwt,
        documentNumber: account.code,
        initialDate: initialdate, //initialDate,
        finalDate: finaldate, //finalDate,
        historical: true, //historical,
        typeCode: typecode, //historical,
        method: "getAccountStatementDetail",
      };
      // console.log("Request Data =>", data);
      let httpAffiliateServices = httpsCallable(
        this.global.functions,
        "httpAffiliateServices"
      );
      return Promise.resolve(
        httpAffiliateServices(data)
          .then((result) => {
            // console.log("getAccountStatementDetail ", result);
            return result;
          })
          .catch((err) => {
            // console.log("err", err);
            // this.global.throwError('user.getAccountStatementDetail()');
            this.global.throwError(
              "Ha ocurrido un error al obtener detalle de resumen (Código de error: 001)"
            );
            throw err;
          })
      );
    },

    //---------------------------------------------------------------------------------------------
    // GET JOBS
    //---------------------------------------------------------------------------------------------
    getJobs: function () {
      if (this.loadState.jobs) {
        return Promise.resolve(this.jobs);
      } else {
        let data = {
          token: this.token,
          jwt: this.jwt,
          documentNumber: this.info.id,
          method: "getJobs",
        };

        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpAffiliateServices"
        );
        return Promise.resolve(
          httpAffiliateServices(data)
            .then((result) => {
              // console.log("JOBS ", result);
              if (result.data.status == 99) {
                this.signOut();
                return false;
              }

              this.jobs = result.data.info.EMPRESA;

              if (!Array.isArray(this.jobs)) this.jobs = [this.jobs];

              this.jobs.forEach((job) => {
                job.code = job["@_IDEMP"];
                job["FEC-INI-APO"] = Number(
                  job["FEC-INI-APO"].toString().slice(0, 4)
                );
                job["FEC-FIN-APO"] = Number(
                  job["FEC-FIN-APO"].toString().slice(0, 4)
                );
                job.loaded = false;
                job.funds = {};
              });

              this.jobs.sort((b, a) => a["FEC-INI-APO"] - b["FEC-INI-APO"]);

              this.loadState.jobs = true;
              return result;
            })
            .catch((err) => {
              // this.global.throwError(err,'user.getJobs()');
              // this.fatalErrorUser=true
              this.global.throwError(
                "No se obtuvo información de empleo (Código de error: 305)"
              );
              throw err;
            })
        );
      }
    },

    //---------------------------------------------------------------------------------------------
    // GET URL FORM LINK
    //---------------------------------------------------------------------------------------------
    getUrlFormLink: function () {
      if (this.loadState.formLink) {
        return Promise.resolve(this.formLink);
      } else {
        const client = new ClientJS();
        const fingerprint = client.getFingerprint();
        let data = {
          token: this.token,
          jwt: this.jwt,
          documentNumber: this.info.id,
          fingerprint: fingerprint,
          method: "getUrlFormUpdateData",
        };
        // console.log("data...:", data);
        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpAffiliateServices"
        );
        return Promise.resolve(
          httpAffiliateServices(data)
            .then((result) => {
              // console.log("getformlink result...:", result);
              if (result.data.status == 99) {
                this.signOut();
                return false;
              }

              // this.formLink = result.data.info.EMPRESA;

              // if (!Array.isArray(this.formLink))
              //   this.formLink = [this.formLink];

              this.formLink = result;

              // this.jobs.forEach((job) =>{
              //   job.code = job['@_IDEMP'];
              //   job['FEC-INI-APO'] = Number(job['FEC-INI-APO'].toString().slice(0, 4));
              //   job['FEC-FIN-APO'] = Number(job['FEC-FIN-APO'].toString().slice(0, 4));
              //   job.loaded = false;
              //   job.funds = {};
              // });

              // this.jobs.sort((b, a) => (a['FEC-INI-APO'] - b['FEC-INI-APO']))

              this.loadState.formLink = true;
              return result;
            })
            .catch((err) => {
              this.global.throwError(err, "user.getUrlFormLink()");
              throw err;
            })
        );
      }
    },

    //---------------------------------------------------------------------------------------------
    // GET CONTRIBUTIONS BY COMPANY
    //---------------------------------------------------------------------------------------------
    getContributionsByCompany: function (company) {
      if (company.loaded) {
        return Promise.resolve(company);
      } else {
        let data = {
          token: this.token,
          jwt: this.jwt,
          documentNumber: this.info.id,
          accountNumber: company.code,
          locationCode: company.LOCAL,
          method: "getContributionsByCompany",
        };

        // console.log("request sent:", data);

        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpAffiliateServices"
        );
        return Promise.resolve(
          httpAffiliateServices(data)
            .then((result) => {
              // console.log("response received:", result);

              if (result.data.status == 99) {
                this.signOut();
                return false;
              }

              company.loaded = true;

              const home = {
                name: "Fondo de Vivienda",
                value: 0,
                percent: 0,
                palette: 1,

                component: {
                  individualVoluntary: {
                    name: "Individual voluntario",
                    value: 0,
                    percent: 0,
                    palette: 1,
                  },
                  individualMandatory: {
                    name: "Individual obligatorio",
                    value: 0,
                    percent: 0,
                    palette: 2,
                  },
                  companyVoluntary: {
                    name: "Empresarial voluntario",
                    value: 0,
                    percent: 0,
                    palette: 3,
                  },
                  companyMandatory: {
                    name: "Empresarial obligatorio",
                    value: 0,
                    percent: 0,
                    palette: 4,
                  },
                  historic: {
                    name: "Fondo histórico",
                    value: 0,
                    percent: 0,
                    palette: 5,
                  },
                  surplus: {
                    name: "Excedentes/Rendimientos",
                    value: 0,
                    percent: 0,
                    palette: 6,
                  },
                },
              };

              const retirement = {
                name: "Fondo de Pensión",
                value: 0,
                percent: 0,
                palette: 2,

                component: {
                  individualVoluntary: {
                    name: "Individual voluntario",
                    value: 0,
                    percent: 0,
                    palette: 1,
                  },
                  individualMandatory: {
                    name: "Individual obligatorio",
                    value: 0,
                    percent: 0,
                    palette: 2,
                  },
                  companyVoluntary: {
                    name: "Empresarial voluntario",
                    value: 0,
                    percent: 0,
                    palette: 3,
                  },
                  companyMandatory: {
                    name: "Empresarial obligatorio",
                    value: 0,
                    percent: 0,
                    palette: 4,
                  },
                },
              };

              const unemployment = {
                name: "Fondo de Cesantía",
                value: 0,
                percent: 0,
                palette: 3,

                component: {
                  companyVoluntary: {
                    name: "Empresarial voluntario",
                    value: 0,
                    percent: 0,
                    palette: 1,
                  },
                  companyMandatory: {
                    name: "Empresarial obligatorio",
                    value: 0,
                    percent: 0,
                    palette: 2,
                  },
                },
              };

              const fundsArray = Array.isArray(result.data.info.DETALLE)
                ? result.data.info.DETALLE
                : [result.data.info.DETALLE];
              fundsArray.forEach((data) => {
                home.component.individualVoluntary.value += data.COTOBL;
                home.component.companyVoluntary.value += data.OTRAPO;
                home.component.historic.value += data.RENHIST;
                home.component.surplus.value += data.RENPREV;

                retirement.component.individualMandatory.value += data.PREVIAFI;
                retirement.component.companyMandatory.value += data.PREVIPAT;
                retirement.component.individualVoluntary.value += data.PREVOAFI;
                retirement.component.companyVoluntary.value += data.PREVOPAT;

                unemployment.component.companyMandatory.value += data.RLABORAL;
                unemployment.component.companyVoluntary.value += data.RLABOVOL;
              });

              company.funds = {
                home,
                retirement,
                unemployment,
              };
              company.value = 0;

              for (let key in company.funds) {
                let fund = company.funds[key];

                fund.value = 0;

                for (let key in fund.component)
                  fund.value += fund.component[key].value;

                if (fund.value > 0)
                  // console.log('fund.component[key]',fund.component[key])
                  // console.log('fund.value',fund.value)
                  for (let key in fund.component)
                    if (fund.value !== 0)
                      fund.component[key].percent =
                        (fund.component[key].value / fund.value) * 100;
                //fund.component[key].percent = Math.round(fund.component[key].value/fund.value*100)

                company.value += fund.value;
              }

              for (let key in company.funds) {
                let fund = company.funds[key];
                // console.log("fund.value", fund.value);
                // console.log("company.value", company.value);
                if (company.value !== 0) {
                  fund.percent = (fund.value / company.value) * 100;
                } else {
                  fund.percent = 0;
                }

                //fund.percent = Math.round(fund.value/company.value*100);
              }

              company.request = result;
              company.loaded = true;
              return company;
            })
            .catch((err) => {
              this.global.throwError(err, "user.getContributionsByCompany()");
              throw err;
            })
        );
      }
    },

    //---------------------------------------------------------------------------------------------
    // HANDLE SESSION TIME
    //---------------------------------------------------------------------------------------------
    handleSessionTime(extend) {
      var time = localStorage.getItem("sti");
      // console.log("SESSION TIME:",time)
      // console.log("Extend:",extend)

      if (time) {
        time = new Date(time);
        const diff = Math.abs(new Date() - time);
        const secs =Math.round((diff / 1000),1);
        // console.log('secs',secs)
        // console.log('useGlobalStore().config.sessionTime',useGlobalStore().config.sessionTime)

        if (secs >= useGlobalStore().config.sessionTime) {
          // console.log("SESSION TIMED OUT:", secs);
          this.signOut();
        } 
        else if (extend)localStorage.setItem("sti", new Date()); 
      } 
      else if (this.jwt && extend) localStorage.setItem("sti", new Date());
    },

    //---------------------------------------------------------------------------------------------
    // SESSION TIME TIMER
    //---------------------------------------------------------------------------------------------
    sessionTimeTimer() {
      // if(this.global.firebaseToken !== null || this.global.rapToken !== null){

        this.handleSessionTime(false);
        setTimeout(this.sessionTimeTimer, 1000);
        // setTimeout(this.sessionTimeTimer, 1000 * 2);
      // }
      

    },

    //---------------------------------------------------------------------------------------------
    // LOG OUT
    //---------------------------------------------------------------------------------------------
    signOut() {
      // this.global.showMenu = false;

      if (this.global.firebaseToken)
        return Promise.resolve(
          signOut(this.global.fireauth)
            .then(() => {

              // console.log("DATA",data);
              // console.log("EXECUTED SIGN OUT");
              this.global.rapToken = null;
              localStorage.removeItem("rto");
              localStorage.removeItem("rti");
              localStorage.removeItem("sti");

              // localStorage.clear();

              this.global.error.active = false;
              this.global.error.ignore = true;


              this.global.firebaseToken = false
              this.global.showMenu = false;
              // this.user.token = false


              Router.push({
                path:'/'
              })
              // Router.push("/").then(Router.go());
            })
            .catch((error) => {
              // console.log('ERROR',error)
              throw error;
            })
        );
    },

    //=======CHARLIES CODE========
    //========= Prestamos =======
    //============================
    //---------------------------------------------------------------------------------------------
    // GET LOANS
    //---------------------------------------------------------------------------------------------
    getLoans() {
      if (this.loadState.loans) {
        // console.log("Hay prestamos en la promesa");
        return Promise.resolve(this.loans);
      } else {
        // console.log("NO hay prestamos en la promesa");
        let data = {
          token: this.token,
          jwt: this.jwt,
          documentNumber: this.info.id,
          method: "getLoans",
        };
        // console.log("this.global.functions", this.global.functions);
        // console.log("Data LOANS", data);

        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpAffiliateServices"
        );
        try {
          return Promise.resolve(
            httpAffiliateServices(data)
              .then((result) => {
                // console.log("LOANS FB ==>>", result);
                if (result.data.status == 99) {
                  this.signOut();
                  return false;
                }

                // return true
                if(result.data.status === 2){
                    //usuario no tiene prestamos
                  return [];
                }
                this.loans = result.data.loans.PRESTAMO;
                // console.log("result.data.loans", result.data.loans.PRESTAMO);
                

                if (!Array.isArray(this.loans)) this.loans = [this.loans];

                this.loadState.loans = true;
                return this.loans;
              })
              .catch((err) => {
                // console.log("ERR ==>>> ", err);
                this.global.throwError("No se obtuvo información de préstamos");
                throw err;
              })
          );
        } catch (error) {
          this.global.throwError("ERROR de función  préstamos");
          throw error;
        }
      }
    },
    // GET LOAN DETAIL
    //---------------------------------------------------------------------------------------------
    getLoanDetail(numberLoan) {
      if (this.loadState.loanDetail) {
        // console.log("Hay prestamos en la promesa");
        return Promise.resolve(this.loans);
      } else {
        // console.log("NO hay prestamos en la promesa");
        let data = {
          token: this.token,
          jwt: this.jwt,
          numberLoan: numberLoan,
          method: "getLoanDetail",
        };
        // console.log("this.global.functions", this.global.functions);
        // console.log("Data LOAN DETAIL", data);

        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpAffiliateServices"
        );
        try {
          return Promise.resolve(
            httpAffiliateServices(data)
              .then((result) => {
                // console.log("LOAN DETAILS FB ==>>", result);
                if (result.data.status == 99) {
                  this.signOut();
                  return false;
                }

                // return true
                this.loanDetail = result.data.loanDetail;
                // console.log("result.data.loans", result.data.loanDetail);
                this.loadState.loanDetail = true;
                return this.loanDetail;
                // return true
              })
              .catch((err) => {
                // console.log("ERR ==>>> ", err);
                this.global.throwError("No se obtuvo información de préstamos");
                throw err;
              })
          );
        } catch (error) {
          this.global.throwError("ERROR de función  préstamos");
          throw error;
        }
      }
    },
  },
});
