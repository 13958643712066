<template>
  <div class = "main flex flex-col h-screen md:flex-row">
    <div class = "view w-full h-full max-h-full bg-lit-blue overflow-y-scroll md:order-2 md:bg-white">
      <router-view></router-view>
      <footer class = "text-sm absolute bottom-0 left-1/2 pb-2 hidden md:block opacity-50">
        RAP @ 2023 | Powered by Beanar.io
      </footer>
      
      <div class = "overlay-bar bg-lit-blue w-full fixed top-0 left-0" v-if="global.firebaseToken">
      </div>
    </div>
    <MenuBar></MenuBar>
  </div>

  <div class = "splash bg-lit-blue fixed w-screen h-screen left-0 top-0 flex transition-opacity duration-500 z-10"
    :class = "[!global.fetchingInternetConnection && !global.firebaseTokenLoading && global.rapToken ? ['opacity-0','pointer-events-none'] : '']">
    <div class = "m-auto w-1/3 text-center md:w-36">
      <img src = "@/assets/png/brand.png" class = "w-full">
      <LoadSpinner class = "w-1/4 mt-6 opacity-25 md:w-1/6"></LoadSpinner>
    </div>
  </div>

  <div class = "error fixed bg-black/50 w-full h-full z-20 top-0 left-0 flex md:bg-black/20 md:backdrop-blur-sm" v-if="global.error.active && !global.error.ignore">
    <div class = "bg-white p-4 w-5/6 rounded-xl m-auto md:w-2/6">
      <div class = "text-center font-bold text-lg mb-2">
        Error
      </div>
      <hr>
      <div class = "text-red my-2 font-mono">
        {{global.error.name}} <br>
        {{global.error.message}}
      </div>
      <hr>
      <div class = "text-xs mt-2">
        SO: {{navigator.oscpu}}<br>
        UA: {{navigator.userAgent}}
      </div>

      <div class = "text-center text-sm mt-2 flex"  @click="logout" v-if="user.fatalErrorUser">
        <div class = "text-center bg-pal-1 rounded-md md:block hover:opacity-70 cursor-pointer drop-shadow-md font-bold
          py-2 px-6 m-auto text-white">
          Cerrar Sesión
        </div>
      </div>

      <div class = "text-center text-sm mt-2 flex"  v-if="!user.fatalErrorUser" v-on:click="global.error.active=false ; this.$router.go(-1)" >
        <div class = "text-center bg-pal-3 rounded-md md:block hover:opacity-70 cursor-pointer drop-shadow-md font-bold
          py-2 px-6 m-auto text-white">
          Aceptar
        </div>

      </div>
    </div>
  </div>

  <div class = "error fixed bg-lit-blue w-full h-full z-20 top-0 left-0 flex p-4 flex-col cursor-pointer"
    v-if="!global.isConnectedToInternet && !global.fetchingInternetConnection" @click="retryConnection()">
    <img src = "@/assets/png/brand.png" class = "w-32 grayscale opacity-75 m-auto mb-0">
    <div class = "m-auto text-center mt-2">
      Tenemos problemas conectándonos a nuestros servidores. Revisa tu conexión o toca para volver a intentarlo.
    </div>
  </div>

</template>

<script>
import MenuBar from './components/MenuBar.vue';
import { useGlobalStore } from './store/GlobalStore';
import { useUserStore } from './store/UserStore';
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions, } from 'firebase/functions';//, connectFunctionsEmulator
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';//, connectFunctionsEmulator
import { initializeAuth, indexedDBLocalPersistence, } from "firebase/auth";//, connectAuthEmulator
// import { initializeAuth, indexedDBLocalPersistence, connectAuthEmulator } from "firebase/auth";//, connectAuthEmulator
import LoadSpinner from './components/LoadSpinner.vue';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { StatusBar, Style } from '@capacitor/status-bar';

export default {
  name: 'App',
  components: {
    MenuBar,
    LoadSpinner
  },

  data() {
    //console.log('NAVIGATOR: ',window.navigator);
    return {
      navigator: window.navigator,
    }
  },

  setup() {
    const global = useGlobalStore();
    const user = useUserStore();

    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_APIKEY, 
      authDomain:process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId:process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket:process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId:process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId:process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
      };

    // const firebaseConfig = {
    //   apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    //   authDomain: "beanrap-dev.firebaseapp.com",
    //   projectId: "beanrap-dev",
    //   storageBucket: "beanrap-dev.appspot.com",
    //   messagingSenderId: "873441408892",
    //   appId: "1:873441408892:web:a67ce7a34fb6ba1bd916f9",
    //   measurementId: "G-Y7PLCWFFQB"
    //   };

//     const firebaseConfig = {
//   apiKey: "AIzaSyApo6WPTZz3lP-cCUaRM4LH0nJpmyFzH1g",
//   authDomain: "apprap-prd.firebaseapp.com",
//   projectId: "apprap-prd",
//   storageBucket: "apprap-prd.appspot.com",
//   messagingSenderId: "794172366097",
//   appId: "1:794172366097:web:bb7b521933d91062fa00b0",
//   measurementId: "G-BN2CK73M62",
// };
    
    const firebase  = initializeApp(firebaseConfig);
    const firestore = getFirestore();
    const firestorage = getStorage();
    const functions = getFunctions(firebase);
    // connectFunctionsEmulator(functions, "localhost", 5001);
    
    // const fireauth = getAuth();

    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios')
      StatusBar.setStyle({ style: Style.Light });

    const fireauth = initializeAuth(firebase, {
      persistence: indexedDBLocalPersistence
    })
  

    //TODO: Revisa esta implementacion
    // if (Capacitor.isNativePlatform()) {
    //     auth = initializeAuth(app, {
    //       persistence: indexedDBLocalPersistence
    //     })
    //   } else {
    //     auth = getAuth()
    //   }

    // connectAuthEmulator(fireauth, "http://localhost:9099");

    global.firebase  = firebase;
    global.firestore = firestore;
    global.functions = functions;
    global.fireauth  = fireauth;
    global.firestorage = firestorage;
    
    return {global, user, firebase, firestore, functions, firestorage}
  },

  created() {
    /*Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
    });*/

    Network.getStatus().then((result)=>{
      this.global.fetchingInternetConnection = false;
      this.global.isConnectedToInternet = result.connected;
      //console.log("GET NETWORK STATUS:",result);
    })
  },

  methods: {
    logout() {
      this.user.signOut()
        .then(()=>{
          this.$router.go();
        })
    },

    retryConnection() {
      localStorage.setItem('exc',true);
      this.$router.go(0);
    }
  }
}
</script>

<style lang = "scss">
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;700&display=swap');

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  }

* {
  touch-action: manipulation;
  }

#app {
  font-family: 'Golos Text', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .view {
    padding-top: env(safe-area-inset-top);
    padding-bottom: calc(env(safe-area-inset-bottom)*0.5);

    .overlay-bar {
      height: env(safe-area-inset-top);
    }
  }
}

.splash {
  z-index: 50;
}

.error {
  z-index: 100;
}

.firebase-emulator-warning {
  display: none !important;
}

</style>